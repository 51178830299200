import React, { useState, useEffect } from 'react'
import './BusinessReport.scss';
import AdminSidebar from '../../components/AdminSidebar/AdminSidebar'
import Navbar from '../../components/Navbar/Navbar'
import PandLFilter from '../../components/PandLFilter'
import BRModal from '../../components/BRModal/BRModal';
import { getEmployeeCommissionReport, getManualInputData } from '../../helper/helper';
import toast, { Toaster } from 'react-hot-toast'
import { cisCommisson, } from '../../components/ReusableFunctions'
import { MenuItem, Select, FormControl, Box, useMediaQuery } from '@mui/material';
import { Button } from "@mui/material";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useTheme } from "@mui/material/styles";
/* import { AreaChart, Area, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from "recharts"; */
import {
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer
} from "recharts";
/* import { filter } from 'lodash'; */
const BusinessReport = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const ismediumScreen = useMediaQuery(theme.breakpoints.down("md"));
    const [selectedStore, setSelectedStore] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [apiDataECR, setApiDataECR] = useState([]);
    const [manualData, setManualData] = useState([]);
    const [storePLData, setStorePLData] = useState("");
    const [storeData, setStoreData] = useState(null);
    const [netProfileLoss, setNetProfileLoss] = useState(null);
    const [dateforModalInput, setDateForModalInput] = useState(null);
    const [cisdata, setCisdata] = useState([]);
    const [eventCisData, setEventCisData] = useState([]);
    const [profileData, setProfileData] = useState([]);
    const [isSidebarVisible, setSidebarVisible] = useState(false);
    const [filterData, setFilterData] = useState('');

    const toggleSidebar = () => {
        setSidebarVisible(prevState => !prevState); // Toggle the sidebar visibility
    };

    const closeSidebarOnClickOutside = (e) => {
        if (isSidebarVisible && !e.target.closest('.admin-sidebar') && !e.target.closest('.hamburger-btn')) {
            setSidebarVisible(false);
        }
    };

    // Add event listener to handle clicks outside the sidebar
    useEffect(() => {
        document.addEventListener('click', closeSidebarOnClickOutside);
        return () => {
            document.removeEventListener('click', closeSidebarOnClickOutside);
        };
    }, [isSidebarVisible]);

    useEffect(() => {
        const today = new Date();
        today.setMonth(today.getMonth() - 1);
        const prevMonth = today.toLocaleString('default', { month: 'long' });
        const prevYear = today.getFullYear();
        const currentDate = prevMonth + ' ' + prevYear
        const data1 = getEmployeeCommissionReport(currentDate);

        data1.then((data2) => {
            setApiDataECR(data2?.data[0]);
        })

    }, []);

    useEffect(() => {
        const currentDate = new Date();
        currentDate.setMonth(currentDate.getMonth() - 1);
        const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        const endDate = lastDayOfMonth.toISOString().split('T')[0];

        const data = getManualInputData({ endDate });
        data.then((res) => {
            setManualData(res.data);
        })
    }, [])
    let one = filterData[0]
    let two = filterData[1]
    let three = filterData[2]
    useEffect(() => {
        setSelectedStore("");

        setStoreData(null);
    }, [one, two, three]);

    const storeNameList = {
        OSHWTIN: "Lake Vista Plaza",
        LAKESIN: "Lakeshore & Islington",
        VYAD2IN: "Center Mall Barton",
        TCPNFIN: "Town Center Plaza",
        VYACBIN: "Cedarbrae Mall",
        BARRIIN: "Barrie",
        VYAREIN: "Rexdale & Islington",
        DTWICIN: "Briarfield Shopping Centre",
        DAN01IN: "Danforth & Woodbine",
        VYOSHIN: "Big Bay",
        VYAMLIN: "Markham & Lawrence",
        VYAWLIN: "Weston & Lawrence",
        DUNBLIN: "Oriental Centre"
    };

    const handleAddPandLChange = (e) => {
        e.preventDefault();

        openModal();
    }
    const openModal = () => {
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);

    };

    const onDataLoaded = (data) => {
        setCisdata(data);
    }

    const onEventDataLoaded = (data) => {
        setEventCisData(data);
    };

    const processData = (data) => {
        if (selectedStore !== "") {
            if (!Array.isArray(data)) return [];
            return data.map(({ month, data }) => {
                const totalSales = data?.revenue["Total Sales"];
                const totalExpense = data?.expense["Total Expense"];
                const netProfit = totalSales - totalExpense;
                return { month: month, netProfit: netProfit }
            })
        } else if (selectedStore === "") {

            if (!Array.isArray(data)) return [];
            return data.map(({ month, data }) => {
                if (!Array.isArray(data) || data.length === 0) return null;
                let totalNetProfit = 0;

                const { plData } = data[0]; // Extract store-wise P&L data
                if (!plData || typeof plData !== "object") return null;

                Object.values(plData).forEach((storeData) => {
                    totalNetProfit += (storeData.revenue["Total Sales"] - storeData.expense["Total Expense"]);
                });
                return { month, netProfit: totalNetProfit };
            })
        }
    };

    const propertiesToCheck = [
        "storeInvoice", "saleInvoice", "tenderedDate", "time", "customerName", "email", "accountNum", "deposit", "retailPrice", "phoneProtectionPlan", "activatedPromotion", "phoneNum", "tempPhoneNum", "simNumber", "imei", "amountYouPaid", "connectionType", "activationDate", "phoneMake", "planName", "planPrice", "myBalanceOwing", "oneTimeCharges", "addons", "autoPayPre", "autoPayPost", "specialistId", "storeId", "user"
    ];

    const removeDuplicates = (arr) => {
        const uniqueObjects = [];
        arr.forEach(item => {
            // Create a string representation of the object, including only the specified properties
            const stringifiedItem = JSON.stringify(
                propertiesToCheck.reduce((obj, key) => {
                    obj[key] = item[key];
                    return obj;
                }, {})
            );

            // Add to uniqueObjects if not already included
            if (!uniqueObjects.some(unique => JSON.stringify(
                propertiesToCheck.reduce((obj, key) => {
                    obj[key] = unique[key];
                    return obj;
                }, {})
            ) === stringifiedItem)) {
                uniqueObjects.push(item);
            }
        });
        return uniqueObjects;
    };

    function storeCommissionFromCis(computedCustomerList) {
        let prepaidCommission = 0;
        let postpaidCommission = 0;
        let hupCommission = 0;
        let addonCommission = 0;
        let hupMargin = 0;
        let deposit = 0;
        let tabHandset = 0;
        let spiffs = 0;

        for (let array = 0; array < computedCustomerList.length; array++) {
            if (computedCustomerList[array].type === "Prepaid") {
                prepaidCommission += computedCustomerList[array].planCommission;
            } else if (computedCustomerList[array].type === "Postpaid with device" || computedCustomerList[array].type === "Postpaid without device") {
                postpaidCommission += computedCustomerList[array].planCommission;
            } else if (computedCustomerList[array].type === "Hup") {
                hupCommission += computedCustomerList[array].planCommission;
            }


            if (computedCustomerList[array].retailHandset * 0.05 < 20 && computedCustomerList[array].retailHandset !== undefined) {
                hupMargin += 20;
            } else if (computedCustomerList[array].retailHandset * 0.05 > 100 && computedCustomerList[array].retailHandset !== undefined) {
                hupMargin += 100;
            } else if (computedCustomerList[array].retailHandset !== undefined) {
                hupMargin += computedCustomerList[array].retailHandset * 0.05;
            }

            if (computedCustomerList[array].deposit !== "Not Required") {
                deposit += parseFloat(computedCustomerList[array].deposit);

            }
            if (computedCustomerList[array].addonCommission !== undefined) {
                addonCommission += computedCustomerList[array].addonCommission
            }
            /* if((computedCustomerList[array].addonCommission === 0 || computedCustomerList[array].addonCommission === "") && computedCustomerList[array].ppp === 'Accepted' ) {
                addonCommission += 27
            } */
            if (computedCustomerList[array].spiff !== undefined) {
                spiffs += computedCustomerList[array].spiff;
            }
            if (computedCustomerList[array].retailHandset !== undefined) {
                tabHandset += computedCustomerList[array].retailHandset;
            }
        }

        return { prepaidCommission, postpaidCommission, hupCommission, addonCommission, hupMargin, deposit, tabHandset, spiffs }
    }

    useEffect(() => {
        if (cisdata.length > 0 && eventCisData.length > 0 && storePLData[0].data.length === 0) {

            const combinedCisData = [...cisdata, ...eventCisData,]
            const uniqueData = removeDuplicates(combinedCisData);
            const storeList = []
            uniqueData.forEach(store => {
                if (!storeList.includes(store.storeInvoice)) {
                    storeList.push(store.storeInvoice)
                }
            })

            const storeData = {}
            storeList.forEach(store => {
                let cisPerStore = uniqueData.filter(item => item.storeInvoice === store)
                let computedCustomerList = cisCommisson(cisPerStore);
                storeData[store] = storeCommissionFromCis(computedCustomerList)
            })

            let totalStoresCom = 0;
            let totalHupMargin = 0;

            if (selectedStore !== "") {

                let postpaidCommission = storeData[selectedStore].postpaidCommission
                let prepaidCommission = storeData[selectedStore].prepaidCommission
                let hupCommission = storeData[selectedStore].hupCommission
                let addonCommission = storeData[selectedStore].addonCommission
                let hupMargin = storeData[selectedStore].hupMargin

                let sum = postpaidCommission + prepaidCommission + hupCommission + addonCommission

                totalStoresCom += sum;
                totalHupMargin += hupMargin;
            } else {
                for (let key in storeData) {
                    if (storeData.hasOwnProperty(key)) {
                        let postpaidCommission = storeData[key].postpaidCommission
                        let prepaidCommission = storeData[key].prepaidCommission
                        let hupCommission = storeData[key].hupCommission
                        let addonCommission = storeData[key].addonCommission
                        let hupMargin = storeData[key].hupMargin

                        let sum = postpaidCommission + prepaidCommission + hupCommission + addonCommission

                        totalStoresCom += sum;
                        totalHupMargin += hupMargin;
                    }
                }
            }

            let autoPopulateData = { totalStoresCom, totalHupMargin }

            let summedRevenue = {
                'Act Com': autoPopulateData.totalStoresCom,
                'HW Margin': autoPopulateData.totalHupMargin,
                'Acc Sales': 0,
                'Top up Margin': 0,
                'Bill Pay Margin': 0,
                'Total Sales': autoPopulateData.totalStoresCom + autoPopulateData.totalHupMargin
            }
            let summedExpense = {
                Payroll: 0,
                'Acc Sales Cost': 0,
                'Mngt Pay': 0,
                'Rental Cost': 0,
                Telephone: 0,
                Internet: 0,
                Gas: 0,
                Hydro: 0,
                Alarm: 0,
                Ensurity: 0,
                Shiftlab: 0,
                'RQ Fee': 0,
                Statflo: 0,
                Moneris: 0,
                'Bank Fee': 0,
                Marketing: 0,
                'Petty Cash': 0,
                'Office Supplies': 0,
                'Store Insurance': 0,
                'QB Fee': 0,
                'Ceridian Fee': 0,
                'Cyber Security': 0,
                'Misc': 0,
                'Total Expense': 0,
            }

            let result = {
                expense: summedExpense,
                revenue: summedRevenue
            };

            const totalRev = result.revenue['Total Sales']
            const totalExp = result.expense['Total Expense']
            const netProfitLoss = (parseFloat(totalRev) - parseFloat(totalExp)).toFixed(2);

            setNetProfileLoss(netProfitLoss)
            setStoreData(result)
        } else {
            return
        }
    }, [cisdata, eventCisData, selectedStore])

    /* function correctTotalSales(data) {
        return data.map((entry) => ({
            ...entry,
            plData: Object.fromEntries(
                Object.entries(entry.plData).map(([store, storeData]) => {
                    if (storeData.revenue) {
                        const revenue = storeData.revenue;

                        // Calculate correct Total Sales
                        const correctTotalSales =
                            (revenue["Acc Sales"] || 0) +
                            (revenue["Act Com"] || 0) +
                            (revenue["Bill Pay Margin"] || 0) +
                            (revenue["HW Margin"] || 0) +
                            (revenue["Top up Margin"] || 0);

                        // Create a new revenue object with the corrected Total Sales
                        const updatedRevenue = {
                            ...revenue,
                            "Total Sales": correctTotalSales,
                        };

                        return [store, { ...storeData, revenue: updatedRevenue }];
                    }
                    return [store, storeData];
                })
            ),
        }));
    } */

    // Example usage
    /* function moveTotalExpenseToEnd(obj) {
        console.log(obj)
        if (!obj || typeof obj !== "object") return obj;

        const { "Total Expense": totalExpense, ...rest } = obj;

        obj = { ...rest, "Total Expense": totalExpense };

        return obj;
    } */

    const setData = (data) => {

        setStorePLData(data ? data : []);
        const profitData = processData(data);
        setProfileData(profitData);

        if (data?.length === 0) {
            toast.success("No P&L data available");
        } else {
            /* let summedExpense = {};
            let summedRevenue = {};

            // Iterate through the array of objects
            correctedData.forEach(obj1 => {
                for (let obj in obj1.plData) {
                    // Iterate through each expense in the object and sum them up
                    for (let key in obj1.plData[obj].expense) {
                        let value = parseFloat(obj1.plData[obj].expense[key]);
                        summedExpense[key] = (summedExpense[key] || 0) + value;
                    }

                    // Iterate through each revenue in the object and sum them up
                    for (let key in obj1.plData[obj].revenue) {
                        // Parse the value as an integer before summing
                        let value = parseFloat(obj1.plData[obj].revenue[key]);
                        summedRevenue[key] = (summedRevenue[key] || 0) + value;
                    }
                }

            }); */

            let totalExpenses = {};
            let totalRevenues = {};

            // Loop through each month's data
            data?.forEach(({ data }) => {
                if (!data || data.length === 0) return;

                const { plData } = data[0]; // Extract store-wise P&L data

                // Iterate through each store in plData
                Object.values(plData).forEach((storeData) => {
                    if (storeData.expense) {
                        Object.entries(storeData.expense).forEach(([key, value]) => {
                            if (typeof value === "number") { // Ensure value is numeric
                                totalExpenses[key] = (totalExpenses[key] || 0) + value;
                            }
                        });
                    }

                    if (storeData.revenue) {
                        Object.entries(storeData.revenue).forEach(([key, value]) => {
                            if (typeof value === "number") { // Ensure value is numeric
                                totalRevenues[key] = (totalRevenues[key] || 0) + value;
                            }
                        });
                    }
                });
            });

            // Convert summedExpense and summedRevenue into the desired format
            let result = {
                expense: /* moveTotalExpenseToEnd(totalExpenses), */totalExpenses,
                revenue: totalRevenues
            };
            const totalRev = result.revenue['Total Sales']
            const totalExp = result.expense['Total Expense']
            const netProfitLoss = (parseFloat(totalRev) - parseFloat(totalExp)).toFixed(2);
            setNetProfileLoss(netProfitLoss)
            setStoreData(result)
        }

    }

    const filterProp = (data) => {
        setFilterData(data);
    }

    const apiDataECRfunction = (data) => {
        setApiDataECR(data);
    };
    const apiAccessoryData = (data) => {
        setManualData(data);
    }
    const dateforModal = (data) => {
        setDateForModalInput(data);
    }

    const handleStoreChange = (e) => {
        e.preventDefault();
        setSelectedStore(e.target.value)
        /* if (value !== "") {

            for (let key1 in storePLData) {

                for (let key in storePLData[key1].plData) {
                    if (key === storeNameList[value]) {
                        storeData['expense'] = moveTotalExpenseToEnd(storePLData[key1].plData[key].expense)
                        storeData['revenue'] = storePLData[key1].plData[key].revenue
                        storeData['store'] = key
                        const totalRev = storePLData[key1].plData[key].revenue['Total Sales']
                        const totalExp = storePLData[key1].plData[key].expense['Total Expense']
                        const netProfitLoss = (parseFloat(totalRev) - parseFloat(totalExp)).toFixed(2);
                        setNetProfileLoss(netProfitLoss)
                    }
                }
            }
            if (!storeData.store) {
                storeData = {
                    store: storeNameList[value],
                    expense: "",
                    revenue: ""

                };
                setNetProfileLoss(null); // Reset the net profit/loss if no data is found
            }
            setStoreData(storeData)
        } else {
            let summedExpense = {};
            let summedRevenue = {};

            // Iterate through the array of objects
            storePLData.forEach(obj1 => {
                for (let obj in obj1.plData) {
                    // Iterate through each expense in the object and sum them up
                    for (let key in obj1.plData[obj].expense) {
                        let value = parseFloat(obj1.plData[obj].expense[key]);
                        summedExpense[key] = (summedExpense[key] || 0) + value;
                    }

                    // Iterate through each revenue in the object and sum them up
                    for (let key in obj1.plData[obj].revenue) {
                        // Parse the value as an integer before summing
                        let value = parseFloat(obj1.plData[obj].revenue[key]);
                        summedRevenue[key] = (summedRevenue[key] || 0) + value;
                    }
                }

            });

            // Convert summedExpense and summedRevenue into the desired format
            let result = {
                expense: moveTotalExpenseToEnd(summedExpense),
                revenue: summedRevenue
            };
            const totalRev = result.revenue['Total Sales']
            const totalExp = result.expense['Total Expense']
            const netProfitLoss = (parseFloat(totalRev) - parseFloat(totalExp)).toFixed(2);

            setNetProfileLoss(netProfitLoss)
            setStoreData(result)

        } */
    };

    useEffect(() => {

        if (selectedStore !== "") {
            let totalExpenses = {};
            let totalRevenues = {};
            const groupedList = [];
            // Loop through each month's data
            storePLData.forEach(({ data }) => {
                if (!data || data.length === 0) return;

                const { plData } = data[0]; // Extract store-wise P&L data

                let filteredPLData = plData[storeNameList[selectedStore]]
                let store = [storeNameList[selectedStore]]
                let result = {
                    [store]: filteredPLData
                };
                groupedList.push({ month: data[0].monthYear, data: filteredPLData })

                // Iterate through each store in plData
                Object.values(result).forEach((storeData) => {
                    if (storeData?.expense) {
                        Object.entries(storeData.expense).forEach(([key, value]) => {
                            if (typeof value === "number") { // Ensure value is numeric
                                totalExpenses[key] = (totalExpenses[key] || 0) + value;
                            }
                        });
                    }

                    if (storeData?.revenue) {
                        Object.entries(storeData.revenue).forEach(([key, value]) => {
                            if (typeof value === "number") { // Ensure value is numeric
                                totalRevenues[key] = (totalRevenues[key] || 0) + value;
                            }
                        });
                    }
                });
            });

            const profitData = processData(groupedList);
            setProfileData(profitData);
            let result = {
                expense: /* moveTotalExpenseToEnd(totalExpenses), */totalExpenses,
                revenue: totalRevenues
            };
            const totalRev = result.revenue['Total Sales']
            const totalExp = result.expense['Total Expense']
            const netProfitLoss = (parseFloat(totalRev) - parseFloat(totalExp)).toFixed(2);
            setNetProfileLoss(netProfitLoss)
            setStoreData(result)
        } else {
            let totalExpenses = {};
            let totalRevenues = {};

            if (storePLData) {
                const profitData = processData(storePLData);
                setProfileData(profitData);
                // Loop through each month's data
                storePLData?.forEach(({ data }) => {
                    if (!data || data.length === 0) return;

                    const { plData } = data[0]; // Extract store-wise P&L data

                    // Iterate through each store in plData
                    Object.values(plData).forEach((storeData) => {
                        if (storeData.expense) {
                            Object.entries(storeData.expense).forEach(([key, value]) => {
                                if (typeof value === "number") { // Ensure value is numeric
                                    totalExpenses[key] = (totalExpenses[key] || 0) + value;
                                }
                            });
                        }

                        if (storeData.revenue) {
                            Object.entries(storeData.revenue).forEach(([key, value]) => {
                                if (typeof value === "number") { // Ensure value is numeric
                                    totalRevenues[key] = (totalRevenues[key] || 0) + value;
                                }
                            });
                        }
                    });
                });

                // Convert summedExpense and summedRevenue into the desired format
                let result = {
                    expense: totalExpenses,
                    revenue: totalRevenues
                };
                const totalRev = result.revenue['Total Sales']
                const totalExp = result.expense['Total Expense']
                const netProfitLoss = (parseFloat(totalRev) - parseFloat(totalExp)).toFixed(2);
                setNetProfileLoss(netProfitLoss)
                setStoreData(result)
            } return
        }



    }, [selectedStore]);

    function formatNegativeNumber(number) {
        // Remove any non-digit characters and convert to a number

        const numericValue = parseFloat(number?.replace(/[^0-9.-]/g, ''));

        // Check if the number is negative
        if (numericValue < 0) {
            // Format the absolute value with commas for thousands separator
            const formattedNumber = Math.abs(numericValue).toLocaleString('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
            });
            // Return the formatted number with parentheses
            return `(${formattedNumber})`;
        }
        // If the number is not negative, return it as is
        return number;
    }

    const gradientOffset = () => {
        const dataMax = Math.max(...profileData.map((i) => i?.netProfit));
        const dataMin = Math.min(...profileData.map((i) => i?.netProfit));

        if (dataMax <= 0) {
            return 0;
        }
        if (dataMin >= 0) {
            return 1;
        }

        return dataMax / (dataMax - dataMin);
    };

    const off = gradientOffset();

    return (
        <div className="br-main">
            <Toaster position='top-center' reverseOrder={false}></Toaster>
            <div className={`admin-sidebar ${isSidebarVisible ? 'visible' : ''}`}>
                <div className="close-btn" onClick={toggleSidebar}>
                    ✖
                </div>
                <AdminSidebar />
            </div>

            <div className="br-container">
                <div className="navbarcontainer">
                    <button className="hamburger-btn" onClick={toggleSidebar}>
                        ☰
                    </button>
                    <Navbar />
                </div>
                <div className="br-block-container">
                    <div className='filter-menu-list'>
                        <div className='filter-menu'>
                            <Box
                                display="flex"
                                gap={2}
                                alignItems={isSmallScreen ? "stretch" : "center"}
                                flexDirection={ismediumScreen ? "column" : "row"}
                                width="100%"
                            >
                                <PandLFilter filterProp={filterProp} onDataLoaded={onDataLoaded} onEventDataLoaded={onEventDataLoaded} setData={setData} apiDataECRfunction={apiDataECRfunction} apiAccessoryData={apiAccessoryData} dateforModal={dateforModal} />
                                <FormControl size="small" sx={{ minWidth: 250 }}>
                                    <Select
                                        id="selectedStore"
                                        value={selectedStore}
                                        onChange={handleStoreChange}
                                        displayEmpty
                                        className='filter-datemenu-select'
                                    >
                                        <MenuItem value="">All Stores</MenuItem>
                                        {Object.keys(storeNameList).map((store, index) => (
                                            <MenuItem key={index} value={store}>
                                                {storeNameList[store]}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>

                        </div>
                        <BRModal
                            isOpen={modalOpen}
                            onClose={closeModal}
                            apiDataECR={apiDataECR}
                            manualData={manualData}
                            dateforModalInput={dateforModalInput}
                        />
                        <div className='button-upload'>
                            <Button
                                variant="contained"
                                color="success"
                                onClick={handleAddPandLChange}
                                sx={isSmallScreen ? { marginTop: 2 } : { marginTop: 0 }}
                            >
                                Upload P&L
                                <FileUploadIcon />
                            </Button>
                        </div>

                    </div>
                    <table className="accountant-table">
                        <thead>
                            <tr>
                                <th>Revenue</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {storeData && Object.entries(storeData?.revenue).map(([category, amount]) => (
                                <tr key={category}>
                                    <td>{category}</td>
                                    <td>
                                        <span>$</span>
                                        <span>{parseFloat(amount).toFixed(2).toLocaleString('en-US', { maximumFractionDigits: 2 })}</span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <table className="accountant-table">
                        <thead>
                            <tr>
                                <th>Expense</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {storeData && Object.entries(storeData?.expense).map(([category, amount]) => (
                                <tr key={category}>
                                    <td>{category}</td>
                                    <td>
                                        <span>$</span>
                                        <span>{parseFloat(amount).toFixed(2).toLocaleString('en-US', { maximumFractionDigits: 2 })}</span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className={`net-profit-loss ${netProfileLoss < 0 ? 'negative' : 'positive'}`}>
                        <label>Net Profit/(Loss):</label>
                        <div className="total-value">
                            <span className="left-span">$</span>
                            <span>{netProfileLoss > 0 ? parseFloat(netProfileLoss).toLocaleString('en-US', { maximumFractionDigits: 2 }) : formatNegativeNumber(netProfileLoss)}</span>
                        </div>
                    </div>
                </div>
                {(filterData[0] === "Quarterly" || filterData[0] === "Yearly") && (

                    <div className='chart'>
                        <ResponsiveContainer width="100%" height={400}>
                            <AreaChart
                                data={profileData}
                            >
                                <CartesianGrid strokeDasharray="3 3" strokeOpacity={0.3} />
                                <XAxis dataKey="month" tick={{ fill: "#555", fontSize: 14 }} />
                                <YAxis tick={{ fill: "#555", fontSize: 14 }} />
                                <Tooltip contentStyle={{ backgroundColor: "#fff", borderRadius: "10px", boxShadow: "0px 2px 10px rgba(0,0,0,0.1)" }} />
                                <defs>
                                    <linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
                                        <stop offset={off} stopColor="green" stopOpacity={1} />
                                        <stop offset={off} stopColor="red" stopOpacity={1} />
                                    </linearGradient>
                                </defs>
                                <Area
                                    type="monotone"
                                    dataKey="netProfit"
                                    stroke="#000"
                                    fill="url(#splitColor)"
                                    strokeWidth={2}
                                />
                            </AreaChart>
                        </ResponsiveContainer>

                    </div>
                )}
            </div>
        </div>

    )
}

export default BusinessReport