import React, { useState, useEffect } from 'react'
import { getPandLData, getEmployeeCommissionReport, getManualInputData, getFilterdByDate, getEventCisData } from '../helper/helper'
import { FormControl, MenuItem, Select, useMediaQuery, Box } from "@mui/material";
import { format, startOfMonth, endOfMonth, startOfQuarter, endOfQuarter, startOfYear, endOfYear } from "date-fns";
import { useTheme } from "@mui/material/styles";

const PandLFilter = ({ setData, apiDataECRfunction, apiAccessoryData, dateforModal, onDataLoaded, onEventDataLoaded, filterProp }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    /* const generateMonths = () => {
        return ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    };

    const generateYears = () => {
          const currentYear = new Date().getFullYear();
        const startYear = 2023;
        const years = [];
        for (let i = 0; i < 7; i++) { // Generate the last 5 years
            years.push(startYear + i);
        }
        return years;
    };

    const months = generateMonths();
    const years = generateYears();
    const [selectedMonth, setSelectedMonth] = useState(months[new Date().getMonth()]);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const dateData = selectedMonth + " " + selectedYear

    function getStartAndEndDate(monthYear) {
        const [monthName, year] = monthYear.split(" ");
        const monthIndex = new Date(`${monthName} 1, ${year}`).getMonth(); // Get month index (0-based)

        const startDate = new Date(year, monthIndex, 1);
        const endDate = new Date(year, monthIndex + 1, 0); // Last day of the month

        return {
            startDate: startDate.toISOString().split("T")[0], // Format: YYYY-MM-DD
            endDate: endDate.toISOString().split("T")[0] // Format: YYYY-MM-DD
        };
    }

    let datesForApi = getStartAndEndDate(dateData)
    const startDate = datesForApi.startDate;
    const endDate = datesForApi.endDate; */
    /* const currentDate = new Date();
    // Calculate the first day of the month
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    // Calculate the last day of the month
    const lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    // Format the first and last day as strings (YYYY-MM-DD)
    const firstDayOfMonthString = firstDayOfMonth.toISOString().split('T')[0];
    const lastDayOfMonthString = lastDayOfMonth.toISOString().split('T')[0];
    const [startDate, setStartDate] = useState(firstDayOfMonthString);
    const [endDate, setEndDate] = useState(lastDayOfMonthString); */

    const [filterType, setFilterType] = useState("Monthly");
    const [month, setMonth] = useState(new Date().getMonth() + 1);
    const [year, setYear] = useState(new Date().getFullYear());
    const [quarter, setQuarter] = useState(1);

    

    useEffect(() => {
        handleFetch();
        filterProp([filterType, month, year])
    }, [filterType, month, year, quarter]);

    const handleFetch = () => {
        let startDate, endDate;

        if (filterType === "Monthly") {
            startDate = format(startOfMonth(new Date(year, month - 1)), "yyyy-MM-dd");
            endDate = format(endOfMonth(new Date(year, month - 1)), "yyyy-MM-dd");
        } else if (filterType === "Quarterly") {
            startDate = format(startOfQuarter(new Date(year, (quarter - 1) * 3)), "yyyy-MM-dd");
            endDate = format(endOfQuarter(new Date(year, (quarter - 1) * 3)), "yyyy-MM-dd");
        } else if (filterType === "Yearly") {
            startDate = format(startOfYear(new Date(year, 0)), "yyyy-MM-dd");
            endDate = format(endOfYear(new Date(year, 0)), "yyyy-MM-dd");
        }


        const today = new Date(endDate);
        const prevYear = today.getFullYear();

        let startMonth, endMonth;

        // Yearly case
        if (filterType === "Yearly") {
            startMonth = 1;  // January
            endMonth = 12;   // December
        }
        // Quarterly case
        else if (filterType === "Quarterly") {
            const quarterMap = {
                1: { start: 1, end: 3 },   // Q1: Jan-Mar
                2: { start: 4, end: 6 },   // Q2: Apr-Jun
                3: { start: 7, end: 9 },   // Q3: Jul-Sep
                4: { start: 10, end: 12 }  // Q4: Oct-Dec
            };
            startMonth = quarterMap[quarter].start;
            endMonth = quarterMap[quarter].end;
        }
        // Monthly case
        else {
            startMonth = today.getMonth() + 1;
            endMonth = startMonth;
        }

        // Generate monthly API calls
        const monthNames = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        const fetchData = async () => {
            try {
                let PLData = [];

                for (let m = startMonth; m <= endMonth; m++) {
                    let monthName = monthNames[m - 1] + " " + prevYear;
                    let { data } = await getPandLData(monthName);
                    PLData.push({ month: monthName, data });

                }

                setData(PLData);
            } catch (error) {
                console.error(error);
            }
        };

        // Call the function
        fetchData();
        async function getRawCisData() {

            try {
                let { data } = await getFilterdByDate({ startDate, endDate });
                let { data: event } = await getEventCisData({ startDate, endDate })
                const today = new Date(endDate);
                const prevMonth = today.toLocaleString('default', { month: 'long' });
                const prevYear1 = today.getFullYear();
                const endDate1 = prevMonth + ' ' + prevYear1

                const { data: data1 } = await getEmployeeCommissionReport(endDate1);

                onEventDataLoaded(event)
                onDataLoaded(data);
                apiDataECRfunction(data1);
            } catch (error) {

            }
        }
        getRawCisData()
    };

    const handleSearch = () => {

        /* async function getRawCisData() {

            const today = new Date(endDate);
            const prevMonth = today.toLocaleString('default', { month: 'long' });
            const prevYear = today.getFullYear();
            const endDate1 = prevMonth + ' ' + prevYear

            try {
                
                let { data } = await getPandLData(endDate1);
                setData(data);
            } catch (error) {
                return error
            }
            const data1 = getEmployeeCommissionReport(endDate1);
            data1.then((data2) => {
                apiDataECRfunction(data2?.data[0]);
            })
            const data = getManualInputData({ endDate });
            data.then((res) => {
                apiAccessoryData(res.data);
            })
            dateforModal(endDate1)

            try {
                let { data } = await getFilterdByDate({ startDate, endDate });
                onDataLoaded(data);
            } catch (error) {
                return error
            }

            try {
                let { data } = await getEventCisData({ startDate, endDate })
                onEventDataLoaded(data)
            } catch (error) {

            }

        }
        getRawCisData(); */
    };

    /* const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    };

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };
 */
    return (
        <Box
            display="flex"
            gap={2}
            alignItems={isSmallScreen ? "stretch" : "center"}
            flexDirection={isSmallScreen ? "column" : "row"}
            width="100%"
        >
            <FormControl size="small">
                <Select value={filterType} onChange={(e) => setFilterType(e.target.value)}>
                    <MenuItem value="Monthly">Monthly</MenuItem>
                    <MenuItem value="Quarterly">Quarterly</MenuItem>
                    <MenuItem value="Yearly">Yearly</MenuItem>
                </Select>
            </FormControl>

            {filterType === "Monthly" && (
                <>
                    <FormControl size="small">
                        <Select value={month} onChange={(e) => setMonth(e.target.value)}>
                            {Array.from({ length: 12 }, (_, i) => (
                                <MenuItem key={i + 1} value={i + 1}>
                                    {format(new Date(2024, i), "MMMM")}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl size="small">
                        <Select value={year} onChange={(e) => setYear(e.target.value)}>
                            {Array.from({ length: new Date().getFullYear() - 2023 + 1 }, (_, i) => 2023 + i).map((yr) => (
                                <MenuItem key={yr} value={yr}>
                                    {yr}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </>
            )}

            {filterType === "Quarterly" && (
                <>
                    <FormControl size="small">
                        <Select value={quarter} onChange={(e) => setQuarter(e.target.value)}>
                            <MenuItem value={1}>Q1 (Jan-Mar)</MenuItem>
                            <MenuItem value={2}>Q2 (Apr-Jun)</MenuItem>
                            <MenuItem value={3}>Q3 (Jul-Sep)</MenuItem>
                            <MenuItem value={4}>Q4 (Oct-Dec)</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl size="small">
                        <Select value={year} onChange={(e) => setYear(e.target.value)}>
                            {Array.from({ length: new Date().getFullYear() - 2023 + 1 }, (_, i) => 2023 + i).map((yr) => (
                                <MenuItem key={yr} value={yr}>
                                    {yr}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </>
            )}

            {filterType === "Yearly" && (
                <FormControl size="small">
                    <Select value={year} onChange={(e) => setYear(e.target.value)}>
                        {Array.from({ length: new Date().getFullYear() - 2023 + 1 }, (_, i) => 2023 + i).map((yr) => (
                            <MenuItem key={yr} value={yr}>
                                {yr}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            )}
        </Box>

    )
}

export default PandLFilter